$background: #01050c;
$foreground: #dddddd;
$foreground-highlight: #996633;

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color: #01050c;
  // color: invert( #01050c);
  background-color: $background;
  background-image: linear-gradient(
    90deg,
    darken($background, 10%),
    lighten($background, 10%)
  );
  background-repeat: no-repeat;
  background-size: auto;
  color: $foreground;
}

.printOnly {
  display: none;
}

a {
  color: $foreground-highlight;
}

@media print {
  body {
    color: black;
    background-color: white;
    background-image: none;
    break-inside: avoid;
  }

  .notForPrint {
    display: none;
  }

  .printOnly {
    display: block;
  }

  .printFooter {
    position: fixed;
    bottom: 0;
  }

  a {
    color: grey;
  }
}

.container {
  max-width: 1048px;
  min-width: 300px;
  margin: auto;
}

.container,
.full,
.half-left,
.half-right {
  box-sizing: border-box;
}

.full,
.half-left,
.half-right {
  padding: 0.5em;
  display: inline-block;
  vertical-align: top;
}

.full {
  width: 100%;
}

.half-left {
  width: 17.99%;
  word-wrap: break-word;
  text-align: right;
}

.half-right {
  width: 81.99%;
  // border-left: 1px solid white;
  border-radius: 1em;
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
  font-weight: normal;
  margin: 0px;
}

h1 {
  font-weight: normal;
  font-size: 3em;
}

h4::before {
  content: "> ";
}

ul {
  list-style-position: outside;
}

.thumb {
  box-sizing: border-box;
  max-width: 100%;
  max-height: 15em;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  border-radius: 1em;
  padding: 0.5em;
}

@media print {
  .thumb {
    display: none;
  }
}

.thumb:hover {
  filter: none;
}
